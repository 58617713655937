const guidanceImageLabel = 
  "Add an image to guide the shape and structure of generated content here. The position and size of this image can be adjusted on the main canvas.";

const refImageLabel = 
  "Add a reference image for style, structure, or object transfer here. The background and each mask region can have different reference images.";

const speedQualityLabel =
  "Number of denoising steps run during image generation. More steps results in higher quality images, but requires more time to execute.";

const imageSharpnessLabel =
  "Intensity strength for anisotropic filtered noise guidance, a technique to enhance the sharpness of generated content.";

const controlStrengthLabel =
  "Intensity strength for applying image guidance to the generated content.";

const promptStrengthLabel =
  "Intensity strength for applying the prompt guidance to the generated content. Higher values may produce oversaturated images.";

const imageHeightLabel =
  "The height of each generated output image in pixels.";

const imageWidthLabel =
  "The width of each generated output image in pixels.";

const inpaintStrengthLabel =
  "The fraction of denoising steps to run on the input image while editing. Lower values preserve more of the source image's original content.";

const maskCropPaddingLabel = 
  "The margin size for the crop applied to the input image and mask before editing. Lower values can improve precision when editing small regions.";

const refAdainScaleLabel =
  "Intensity strength for reference AdaIN, a technique to transfer color palettes, lighting, and artistic style from a reference image.";

const refAttnScaleLabel =
  "Intensity strength for reference attention, a technique to transfer textures, materials, and local structures from a reference image.";

const imageSeedLabel =
  "An arbitrary number controlling the details of the generated content. Useful for reproducing previous results or running controlled experiments.";

const modelAdapterLabel = 
  "The name of a fine-tuned adapter to add to the model weights before inference. Useful for customized depictions of objects or styles from a dataset of images."

const modelAdapterWeightLabel = 
  "Intensity strength for adding weights from a fine-tuned adapter before inference. Useful for adjusting the strength of customization in generated content."

const tooltipConstants = {
  guidanceImageLabel,
  refImageLabel,
  speedQualityLabel,
  imageSharpnessLabel,
  controlStrengthLabel,
  promptStrengthLabel,
  imageHeightLabel,
  imageWidthLabel,
  inpaintStrengthLabel,
  maskCropPaddingLabel,
  refAdainScaleLabel,
  refAttnScaleLabel,
  imageSeedLabel,
  modelAdapterLabel,
  modelAdapterWeightLabel,
};

export default tooltipConstants;
